export const RulesetsList = [
  "Dungeons & Dragons 5th Edition",
  "Savage Worlds",
  "Pathfinder",
//   "Starfinder",
//   "Shadowrun",
//   "Call of Cthulhu",
//   "Fate",
//   "Mutants & Masterminds",
//   "Vampire: The Masquerade",
//   "Werewolf: The Apocalypse",
//   "Mage: The Ascension",
//   "Star Wars D20",
//   "Star Wars Edge of the Empire",
];

export default RulesetsList;