/* eslint-disable react/style-prop-object */
import React from "react";
import AdsTxt from "../../../models/AdsTxt";

function AdsTxtComponent() {

    return <div style={{ whiteSpace: "pre-wrap"} } >
       {AdsTxt}
    </div>
}

export default AdsTxtComponent;
