import React from "react";
import "../../App.css";
function TermsAndConditions() {

    return (
        <div>
            <iframe title="Terms And Conditions" className="policy-doc" src="policies/terms.htm"></iframe> 
        </div>
    );
}


export default TermsAndConditions;
