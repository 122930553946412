export enum Rulesets {
  DnD5E = "Dungeons & Dragons 5th Edition",
  SWADE = "Savage Worlds",
  PF = "Pathfinder",
  SF = "Starfinder",
  SR = "Shadowrun",
  CoC = "Call of Cthulhu",
  Fate = "Fate",
  MnM = "Mutants & Masterminds",
  VtM = "Vampire: The Masquerade",
  WtA = "Werewolf: The Apocalypse",
  MtA = "Mage: The Ascension",
  EotE = "Star Wars Edge of the Empire",
}