const promptModifiers = {
    shopkeeper:"This character is a shopkeeper so generate a shop inventory for them including a shop description, item descriptions, item rarity, prices and item effects.",
    includeStats:"a stat block for this character, which must include at least one attack/weapon, spell/power, or otherwise offensive ability",
    includeBackground:"a detailed background and/or history for this character",
    includeLoot: "a list of loot and/or personal items for this character containing at least 1 item",
    includeDescription:"a detailed description of this character including at least 1 quirk and 1 goal. Do not reuse the prompt in the description",
}

export default promptModifiers

