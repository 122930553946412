export const defaultPalette = {
    primary: {
        main: "#D6FAFF",
    },
    secondary: {
        main: "#439775",
    },
    info: {
        main: "#1481BA",
    },
    error: {
        main: "#FF6B6B",
    },
    text: {
        main: "#001021",
    },
};

export default defaultPalette;