import React from "react";
import "../../App.css";
function PrivacyPolicy() {

    return (
        <div>
            <iframe title="Privacy Policy"className="policy-doc" src="policies/privacy-policy.htm"></iframe> 
        </div>
    );
}


export default PrivacyPolicy;
